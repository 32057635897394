import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import { closePopup, createArtworkLibraryPopup } from '../actions/popup';
import { createUploadFile } from '../actions/file';
import { createFinalizeAddProductImage } from '../actions/index';
import * as actions from '../actions/item';
import { createUpdate } from '../actions';
import { createAddCallout, createDismissCallout } from '../actions/callout';
import { createAddTemp } from '../actions/temp';

import { getFullItem } from '../selectors';

import { getImageSrc } from '../utils';
import { BASE_ZINDEX } from '../popup-factory';

import Callout from './Callout';

const TEMP_FILE_REGISTER = 'artwork_file_id';

class UploadProductImagePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      url: ''
    };

    this.handleChangeUrl = this.handleChangeUrl.bind(this);
    this.handlePressEnter = this.handlePressEnter.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  /* UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loading === false) {
      this.props.onClosePopup(nextProps.closeOnLoad ? -1 : 0);
    }
  } */

  handleChangeUrl(e) {
    this.setState({ url: e.target.value });
  }

  handlePressEnter(e, item_id, url, onUploadImageUrl, onClosePopup) {
    if(item_id) {
      if ('Enter' === e.key) {
        if(url) {
          onUploadImageUrl(item_id, url);
          onClosePopup();
        }else{
          this.props.onAddCallout('no_url', 'Please fill in the url.');
        }
      }
    }else{
      if ('Enter' === e.key) {
        if(url) {
          this.props.onCreateAddTemp(url);
        }else{
          this.props.onAddCallout('no_url', 'Please fill in the url.');
        }
      }
    }
  }

  handleOnBlur(e, item_id, url, onUploadImageUrl, onClosePopup) {
    if(item_id) {
      if(url && url != '') {
        onUploadImageUrl(item_id, url);
        onClosePopup();
      }
    }else{
      if(url && url != '') {
        this.props.onCreateAddTemp(url);
      }
    }
  }

  render() {
    const { identity, item, artwork_file, artwork_file_id, onClosePopup, onUploadProductImage, onCreateArtworkLibraryPopup, onUpdateItem, onUploadImageUrl, messages, onAddCallout, onDismissCallout, loading, onCreateAddTemp, index } = this.props;
    const url = this.state.url;

    const includeClientLibrary = item && 'SUPPLIER' !== identity.company_type;
    return (
      <div id="upload-product-image-modal" className="reveal large" style={{ height: 'auto', display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            {messages.map(message =>
              <Callout key={message.key} type={message.type} onClose={onDismissCallout(message.key)}>
                <p>{message.message}</p>
              </Callout>
            )}
            <h3 id="modalTitle">Upload Product Image</h3>
          </div>
        </div>
        <div className="row popup-content">
          <div className={`small-12 ${includeClientLibrary ? 'medium-4' : 'medium-6'} columns`} style={{'borderRight' : 'solid 1px'}}>
            <Dropzone onDrop={item ? onUploadProductImage(item.item_id) : onCreateAddTemp} style={{border: 'none'}} multiple={false}>
              <>
                From your computer
                <br />
                <button className="tiny button" type="button">Choose File</button>
              </>
            </Dropzone>
          </div>
          {includeClientLibrary ?
            <div className="small-12 medium-4 columns" style={{'borderRight' : 'solid 1px'}}>
                From the client's library
                <br />
                <button className="tiny button" type="button" onClick={e => {e.preventDefault(); onCreateArtworkLibraryPopup(TEMP_FILE_REGISTER, item); }}>Open library</button>
                <br />
                {artwork_file ?
                  <div>
                    <p>{artwork_file[0].file_display_name} selected.</p>
                    <button
                      className="alt tiny button"
                      onClick={e => {
                        e.preventDefault();
                        onUpdateItem(
                          item.item_id,
                          'image_id',
                          item.image_id
			)(artwork_file_id);
                        onClosePopup();
		      }}>Change product image</button>
                  </div>
                : null}
            </div>
          : null}
          <div className={`small-12 ${includeClientLibrary ? 'medium-4' : 'medium-6'} columns`}>
              From the web
              <input type="text" value={url} placeholder="http://www..." onChange={this.handleChangeUrl}
                    onKeyDown={e => {item ? this.handlePressEnter(e, item.item_id, url, onUploadImageUrl, onClosePopup) : this.handlePressEnter(e, null, url, null, onClosePopup);}}
                    onBlur={e => {item ? this.handleOnBlur(e, item.item_id, url, onUploadImageUrl, onClosePopup) : this.handleOnBlur(e, null, url, null, onClosePopup);}} />
          </div>
          {loading ?
            <div className="small-12 columns">
              <div className="text-center"><br /><img src="/images/gears.gif" /><br /></div>
            </div>
          : null}
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = ownProps.item_id ? getFullItem(state, { item_id: ownProps.item_id }) : null;
  const artwork_file = state.temp.artwork_file_id ? Object.values(state.entities.files).filter(f => f.file_id == state.temp.artwork_file_id) : null;
  const artwork_file_id = state.temp.artwork_file_id ? state.temp.artwork_file_id : null;
  return {
    item,
    artwork_file,
    artwork_file_id,
    loading: state.display.loading.upload_file,
    messages: state.display.messages || [],
    identity: state.identity
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  return {
    onClosePopup: (index) => {
      dispatch(closePopup(index));
    },
    onUploadProductImage: item_id => files => {
      return dispatch(createUploadFile(item_id, 'ITEM', files[0])).then(
        action => dispatch(createFinalizeAddProductImage(item_id, action.payload.data.file_id))
      ).then(() => dispatch(closePopup()));
    },
    onCreateArtworkLibraryPopup: (file_register, item) => {
      dispatch(createArtworkLibraryPopup(file_register, item, ownProps.account_id, ownProps.account_type));
    },
    onUpdateItem: (item_id, field, previous_value) => value => {
      dispatch(updateItem(item_id, field, previous_value, value));
    },
    onUploadImageUrl: (item_id, url) => {
      return dispatch(createUploadFile(item_id, 'ITEM', url)).then(
        action => dispatch(createFinalizeAddProductImage(item_id, action.payload.data.file_id))
      ).then(() => dispatch(closePopup()));
    },
    onDismissCallout: key => () => {
      dispatch(createDismissCallout(key));
    },
    onAddCallout: (key, message) => {
      dispatch(createAddCallout(key, message));
    },
    onCreateAddTemp: files => {
      dispatch(createAddTemp('TEMP_NEW_PRODUCT_IMAGE', files));
      dispatch(closePopup());
    }
  };
};

const ConnectedUploadProductImagePopup = connect(mapStateToProps, mapDispatchToProps)(UploadProductImagePopup);
export default ConnectedUploadProductImagePopup;

